.App {
  #text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header-content {
  padding: 10px;
}

.App-link {
  color: #61dafb;
}

.dataTableLayout {
  margin-top: 20px;
}

.App-rayout {
  border: 1px solid darkgray;
  padding: 10px;
}

.App-label {
  width: 200px;
  display: table;
}

.App-content {
  border: 1px solid black;
  width: 350px;
  height: 40px;
  display: inline-block;
  text-align: center;
  font-size: 20px;
}

.App-table {

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
